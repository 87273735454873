import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactSelect from "../../UI/ReactSelect";
import ReactMultiSelect from "../../UI/ReactMultiSelect";
import {
  useGetCategoriesQuery,
  useCreateCategoriesMutation,
} from "redux/api/Categories/categoriesApi";
import RenderIf from "app/modules/UI/RenderIf";
import { useGetCitiesQuery } from "redux/api/Searchkeyword/searchKeywordApi";

export default function Create(props) {
  const { show, onHide } = props;
  const [sourceOption, setSourceOption] = React.useState(null);
  const [sourceOptions, setSourceOptions] = React.useState(null);
  const [searchKeywords, setArray] = React.useState([]);
  const [searchKeywordsOption, setSearchKeywordsOption] = React.useState(null);
  const [searchKeywordsOptions, setSearchKeywordsOptions] = React.useState(
    null
  );

  const initialValues = {
    name: "",
    parentCategoryId: "",
    searchKeywords: [],
  };



  const { data } = useGetCategoriesQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
  });
  const { data: SearchKeywordDat } = useGetCitiesQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
  });

  useEffect(() => {
    if (SearchKeywordDat) {
      const newSelectData = SearchKeywordDat?.data.map((item) => ({
        value: item.id,
        label: item.expression,
      }));
      setSearchKeywordsOptions([...newSelectData]);
    }
  }, [SearchKeywordDat]);
  useEffect(() => {
    if (data) {
      const newSelectData = data?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSourceOptions([{ label: "Heçbiri", value: 0 }, ...newSelectData]);
    }
  }, [data]);

  function handleChangeInterest(c) {
    setSearchKeywordsOption(c);
    const mockArray = [];
    c.forEach((element) => {
      mockArray.push(element.value);
    });
    setArray(mockArray);
    setFieldValue("searchKeywords", mockArray);
  }

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    createCategories,
    { isLoading, error, isSuccess },
  ] = useCreateCategoriesMutation();
  useEffect(() => {
    if (isSuccess) {
      resetForm();
      onHide();
    }
  }, [isLoading]);

  const CreateSchema = Yup.object().shape({
    name: Yup.string().required("Başlıq daxil etməyiniz tələb olunur."),
  
  });

  function handleChangeRole(value) {
    setSourceOption(value);
    setFieldValue("parentCategoryId", value?.value);
  }

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }

    return false;
  };

  const onPostHumanResource = (values) => {
    const humanResource = {
      name: values?.name,
      parentCategoryId:
        values?.parentCategoryId == 0 ? null : values?.parentCategoryId,
      searchKeywords: searchKeywords,
    };

    createCategories(humanResource);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPostHumanResource(values, resetForm, setSubmitting);
    },
  });

  const { resetForm,  setFieldValue } = formik;


  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Əlavə et</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="name"
                    name="name"
                    label="Ad"
                    placeholder="Ad"
                    className="w-100"
                    error={getInputClasses("name")}
                    {...formik.getFieldProps("name")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf condition={formik.touched.name && formik.errors.name}>
                <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                </RenderIf>
                
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <ReactSelect
                    suggestions={sourceOptions}
                    handleChangeSingle={(value) => handleChangeRole(value)}
                    single={sourceOption}
                    label="Daxil olduğu kategoriya"
                    placeholder="Daxil olduğu kategoriya"
                    inputId="parentCategoryId"
                  />
                </div>
                <RenderIf condition={formik.touched.parentCategoryId &&
                formik.errors.parentCategoryId}>
                 <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.parentCategoryId}
                    </div>
                  </div>
                </RenderIf>
           
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <ReactMultiSelect
                    isMulti={true}
                    suggestions={searchKeywordsOptions}
                    handleChangeSingle={handleChangeInterest}
                    value={searchKeywordsOption}
                    label="Açar Sözlər"
                    placeholder="Açar Sözlər"
                    inputId="id"
                  />
                </div>
                <RenderIf condition={formik.touched.searchKeywords &&
                formik.errors.searchKeywords}>
                 <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.searchKeywords}
                    </div>
                  </div>
                </RenderIf>
              
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
     
            }}
          >
            Bağla
          </Button>
          <Button type="submit"  className={`px-9`}>
            Əlavə et
            {isLoading && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
